<template>
  <div>
    <!--탑메뉴-->
    <topbar-comp></topbar-comp>
    <left-bar-comp>
      <sports-left-bar-comp></sports-left-bar-comp>
    </left-bar-comp>

    <div class="content_section">
      <div class="content">
        <sub-title>이벤트</sub-title>
        <div class="board_list">
          <div class="event_detail" v-if="item.eventImg != null">
            <img  alt="" :src="item.eventImg">
          </div>
          <div class="event_detail" v-if="item.content != null" v-html="item.content">

          </div>
          <div class="go_back" style="display: flex;align-items: center;justify-content: center">
            <button @click="goback()" style="background-color: var(--reegbtnbg01);border-radius: 2px">리스트 보기</button>
          </div>

        </div>
      </div>
    </div>
    <!-- right 메뉴 -->
    <right-bar-comp>
      <!--내정보-->
      <user-info-comp slot="userinfo"></user-info-comp>
      <right-buttons-comp slot="btns"></right-buttons-comp>
      <!--배팅카트-->
      <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
      <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
    </right-bar-comp>

    <foot-comp></foot-comp>
  </div>


</template>

<script>
  import TopbarComp from "../../components/TopbarComp";
  import SubTitle from "../../components/SubTitle";
  import SportsBetListComp from "../../components/sports/SportsBetListComp";
  import RightBarComp from "../../components/RightBarComp";
  import RightBarBannerComp from "../../components/RightBarBannerComp";
  import LeftBarComp from "../../components/LeftBarComp";
  import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
  import FootComp from "../../components/FootComp";
  import {getBoardDetail, saveBoardComment} from "../../network/userRequest";
  import CommentsComp from "../../components/CommentsComp";
  import sportsConst from "../../common/sportsConst";
  import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
  import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
  import {postionMixin} from "../../common/mixin";
  import UserInfoComp from "../../components/UserInfoComp";
  import RightButtonsComp from "../../components/RightButtonsComp";
  import SportsBetCartComp from "../../components/sports/SportsBetCartComp";

  export default {
    name: "EventDetail",
    mixins:[postionMixin],
    components: {
      SportsBetCartComp,
      RightButtonsComp,
      UserInfoComp,
      SportsLeftBarComp,
      CommentsComp,
      FootComp,
      LeisureLeftComp, LeftBarComp, RightBarBannerComp, RightBarComp, SportsBetListComp, SubTitle, TopbarComp
    },
    data() {
      return {
        item: {id: 0, eventImg: '', commentList: [], commentable: 1},
        boardId: 0,
        sportsConst,
        position:"이벤트",
      }
    },
    methods: {
      boardDetail() {
        this.$store.commit(RECEIVE_SHOW_LOADING)
        getBoardDetail(this.boardId).then(res => {
          this.item = {}
          if (res.data.success) {
            this.item = res.data.data
          }
          this.$store.commit(RECEIVE_HIDE_LOADING)
        })
      },
      commentSuccess() {
        this.boardDetail()
      },
      goback() {
        this.$router.go(-1)
      },
    },
    created() {
      this.boardId = this.$route.params.id
      this.boardDetail()
    }
  }
</script>

<style scoped>
  @import url("../../assets/css/base.css");
  @import url("../../assets/css/common.css");
  @import url("../../assets/css/style.css");
  @import url("../../assets/css/media.css");
  @import url("../../assets/css/sports.css");
  .board_list{
    box-sizing: border-box;
    padding: 10px 0;
  }
  .board_list .event_detail {
    box-sizing: border-box;
    max-width: 700px;
    margin: 0 auto;
  }
  .event_detail img{
    width: 100%;
  }
  .go_back button {
    width: 120px;
    font-size: 12px !important;
    margin: 10px 0;
    padding: 10px;
  }
  @media screen and (max-width: 1024px) {
    .board_list{
      box-sizing: border-box;
      padding: 10px 10px!important;
    }
  }
</style>